body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* @media (-webkit-device-pixel-ratio: 1.25) {
  body {
    zoom: 0.80;
  }
}
@media (-webkit-device-pixel-ratio: 1.5) {
  body {
    zoom: 0.80;
  }
} */

@media (-webkit-min-device-pixel-ratio: 1.1) and (-webkit-max-device-pixel-ratio: 1.99) {
  body {
    zoom: 0.67;
  }

}