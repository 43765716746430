:root {
    --fntlbl: normal normal 700 14px/1.4em avenir-lt-w01_35-light1475496,sans-serif;
    --textAlign: left;
    --dir: ltr;
    --txtlbl: 97,97,95;
    --labelMarginBottom: 14px;
    --alpha-brd: 1;
    --txt_placeholder: 0,0,0;
    --font_8: normal normal normal 15px/1.4em proxima-n-w01-reg,sans-serif;
    --color_15: 0,0,0;
  }


.banner_main {
    background-color:#002E5D;
    display: grid;
}

.unilever_logo_div {
    margin: 12px 0px 12px calc((100% - 980px) * 0.5);
    left: -232px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
    position: relative;
}

.unilever_logo {
    width: 210px;
    height: 70px;
}

.header_div {
    position: inherit;
    margin-left: calc((100% - 980px) * 0.5);
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: center;
}

.header_text {
    font-size: 60px;
    margin: 0;
    line-height: normal;
    letter-spacing: normal;
    color: #FFFFFF;
    padding-bottom: 10px;
    padding-left: 110px;
}

.body_div {
    position: inherit;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
    width: 954px;
    font-size: 10px;
    font-family: 'proxima-n-w01-reg', sans-serif;
    text-align: left;
    height: auto;

}

.margin_top_p {
    margin: 23px 0px 20px calc((100% - 980px) * 0.5);
}

.margin_mid_p {
    margin: 0px 0px 20px calc((100% - 980px) * 0.5);
}

.margin_low_p {
    margin: 0px 0px 25px calc((100% - 980px) * 0.5);
}

.dashed_div {
    border-bottom: 1px dashed hsla(0,0%,100%,.5);
    border-top: 1px dashed rgba(0,0,0,.35);
}

.border_div {
    position: inherit;
    margin: 0px 0px 7px calc((100% - 980px) * 0.5);
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
    width: 954px;
    height: 20px;
    transform-origin: center center;
}

.margin_step_p {
    margin: 0px 0px 27px calc((100% - 980px) * 0.5);
}

.step_wrapper {
    display: flex;
    margin-left: 7%;
    margin-bottom: 21px;
    align-items: center;
    justify-content: space-between
  }
    .step1{
      gap: 10px;
    }

    .step3{
      flex-direction: column;
    }
    
    .step3 button{
      align-self: flex-end;
    }
.text_box{
    border-color: var(--corvid-border-color,rgba(var(--brd,227,227,227),var(--alpha-brd,1)));
    box-sizing: border-box!important;
    resize: none;
    width: 100%;
    -webkit-appearance: none;
    box-shadow: var(--shd,0 0 0 transparent);
}
.select_div{
    flex: 1;
    display: flex;
    flex-direction: column;
}
.market_div{
  flex: 0.95
}

.language_div{
  flex: 1.05;
}

.ipgln_div{
  flex: 2;
}

.assortment_div{
  flex: 1.25;
}

.label_text {
    font: var(--fntlbl);
    line-height: 1;
    padding: var(--labelPadding);
    text-align: var(--textAlign);
    direction: var(--dir);
    color: rgb(var(--txtlbl,var(--color_15)));
    word-break: break-word;
    display: inline-block;
    line-height: 1;
    margin-bottom: var(--labelMarginBottom,14px);
    font-weight: 545;
}

.label_class {
    font-size: 15px;
    font-family: sans-serif;
    color: rgb(20, 20, 150);
    margin-bottom: var(--labelMarginBottom,14px);
}

.radio_label {
    font-size: 15px;
    font-family: sans-serif;
    color:  rgb(20, 20, 150);
    margin-left: 15px;
    align-self: center;
    font-weight: normal;
}


.select_dropdown {
    width: 80%;
    height: 31px;
    border-width: medium;
    color: rgb(var(--txt_placeholder,136,136,136));
    border-style: solid;
    margin: 0;
}

.file_text{
    margin-left: 10%;
    color:  rgb(20, 20, 150);
    font-family: sans-serif;
    font-size: 15px;
}

.checkbox_main {
    font: var(--fntlbl);
    line-height: 1;
    padding: var(--labelPadding);
    text-align: var(--textAlign);
    direction: var(--dir);
    color: rgb(var(--txtlbl,var(--color_15)));
    word-break: break-word;
    display: inline-block;
    line-height: 1;
    margin-bottom: var(--labelMarginBottom,14px);
    font-weight: 545;
    font-size: 14px;
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
}

.checkbox {
    width: 15px;
    height: 15px;
}

.step_header_text {
    font-size: 17px;
    display: inline-flex;
    margin-bottom: 30px;
}

.step2 {
    font-size: 17px;
    display: inline-flex;
}

.email_section {
    font-size: 17px;
    display: inline-flex;
    margin-top: 5px;
}


.button_ui{
    height: 40px;
    color: white;
    transition: inherit;
    justify-content: center;
    width: 140px;
    align-items: center;
    overflow: hidden;
    border-radius: 50px;
    background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient( 
180deg
 , #243391 0%, rgba(87, 29, 127, 0) 100%), #571D7F;
}

.button_ui:disabled{
    height: 40px;
    color: white;
    transition: inherit;
    justify-content: center;
    width: 140px;
    align-items: center;
    overflow: hidden;
    border-radius: 50px;
    background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient( 
180deg
 , #818496 0%, rgba(87, 29, 127, 0) 100%), #959198;
}

.button_ui:disabled:hover{
    height: 40px;
    color: white;
    transition: inherit;
    justify-content: center;
    width: 140px;
    align-items: center;
    overflow: hidden;
    border-radius: 50px;
    background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient( 
180deg
 , #818496 0%, rgba(87, 29, 127, 0) 100%), #959198;
}

.button_ui:hover {
    background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(
180deg
, #243391 0%, rgba(87, 29, 127, 0) 100%), #243391;
  }

p {
    font-size: 17px;
}



h1 { font-weight:normal; }

.checkbox_div {
    float: right;
    padding-right: 150px;
    position:relative
}

.checkbox_left{
    padding-right: 50px;

}
.checkbox_right{
  position: relative;
  left: 20px

}
/* .market_div {
    display: relative;
} */

.step_status_message {
  position: relative;
}

.loader {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid rgb(20, 20, 150); /* Blue */
    border-radius: 50%;
    animation: spin 2s linear infinite;
    width: 15px;
    height: 15px;
    display: none;
  }
  .step1_loader_position {
    position: absolute;
    margin-left: 185px;
    margin-top: 34px;
  }
  .step3_loader_position {
    position: absolute;
    margin-left: 30px;
  }
  .step3_timer_position{
    position: absolute;
    margin-left: 0px;
  }
  .step4_loader_position {
    position: absolute;
    margin-left: 35px;
  }
  .step4_timer_position {
    position: absolute;
    margin-left: 0px;
  }
  .step5_loader_position {
    position: absolute;
    margin-left: -50px;
    margin-top: 10px;
  }
  .step5_timer_position {
    position: absolute;
    margin-left: -85px;
    margin-top: 10px;
  }

  .timer {
    font-size: 17px;
  }




  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }






  /**
 * Main wrapper
 */
.select-search {
    /* width: 175px; */
    position: relative;
    font-family: sans-serif;
    box-sizing: border-box;
   
  }
  
  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }
  
  /**
  * Value wrapper
  */
  .select-search__value {
    position: relative;
    z-index: 0;
  }
  
  .select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
  }
  
  /**
  * Input
  */
  .select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: 1px solid grey;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    font-family: sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
  }
  
  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  
  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }
  
  /**
  * Options wrapper
  */
  .select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  }
  
  /**
  * Options
  */
  .select-search__options {
    list-style: none;
    margin: 0;
    padding-inline-start: 0;
  }
  
  /**
  * Option row
  */
  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }
  
  /**
  * Option
  */
  .select-search__option,
  .select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }
  
  .select-search--multiple .select-search__option {
    height: 48px;
  }
  
  .select-search__option.is-selected {
    background: #002E5D;
    color: #fff;
  }
  
  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: #9ab0e8;
  }
  
  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: #4a6ec8;
    color: #fff;
  }
  
  /**
  * Group
  */
  .select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
  }
  
  /**
  * States
  */
  .select-search.is-disabled {
    opacity: 0.5;
  }
  
  .select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
  }
  
  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
  }
  
  /**
  * Modifiers
  */
  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }
  
  .select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
  }
  
  .select-search--multiple .select-search__input {
    cursor: initial;
  }
  
  .select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
  }
  
  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }
  
  .select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #0c2d83;
  }
  
  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
  }
  
  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
  }
  
  .select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
  }
  

  /**
  creds
  */

  .creds {
    display: block;
    /* margin-top: 20px; */
  }

  .creds-field {
    margin-top: 10px;
  }
  .creds-field.password {
    margin-top: 15px;
  }

  .credlabel {
    display: inline-block;
    font-size: 17px;
    width: 70px;
    
  }

  .cred-input {
    display: inline-block;
  }

  .cred-input input {
    width: 500px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid grey;
    box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 15%);

  } 

  .cred-input input:hover {
    border-color: #002E5D;
  }

  .cred-input input:focus {
    border-color: #002E5D;
  }

  .email_box{
    border-color: var(--corvid-border-color,rgba(var(--brd,227,227,227),var(--alpha-brd,1)));
    box-sizing: border-box!important;
    resize: none;
    width: 505px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid grey;
    box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 15%);
}

.btn-augment {
  align-items: baseline;
}


/* #rnddata, #ecomdata, #shopifydata{
  width: 17px;
  height: 17px;
} */

/* .step_chkbox_container {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
} */

.step_chkbox_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  row-gap: 12px;
  column-gap: 0px;
  width: 100%;
}

.step_chkbox_container > div > input {
  width: 17px;
  height: 17px;
  vertical-align: middle;
  margin-right: 10px;
}

/* .selectall {
  padding-top: 15px;
  margin-top: 5px;
  border-top: solid 1px grey;
} */